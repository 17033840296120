import Image from "aldoo-ra/Image"
import { useEffect, useRef } from "react"

const variants = {
  // Double, zigzag (Partners, Affiliates, Blog, Article) 
  default: {
    left: {
      top: "300px",
      left: 0,
      width: "w-[165px]",
      hidden: false
    },
    middle: {
      top: "90px",
      left: "45%",
      width: "w-[150px]",
      hidden: true
    },
    right: {
      top: "-100px",
      right: 0,
      width: "w-[172px]",
      hidden: false
    }
  },

// Double, top (IndexPage)
  variant1: {
    left: {
      top: "70px",
      left: 0,
      width: "w-[165px]",
      hidden: false
    },
    middle: {
      top: "90px",
      left: "45%",
      width: "w-[150px]",
      hidden: true
    },
    right: {
      top: "70px",
      right: 0,
      width: "w-[172px]",
      hidden: false
    }
  },

  // Triple (Pricing, AboutUs, ContactUs)
  variant2: {
    left: {
      top: "480px",
      left: 0,
      width: "w-[165px]",
      hidden: false
    },
    middle: {
      top: "50px",
      left: "22%",
      width: "w-[150px]",
      hidden: false
    },
    right: {
      top: "-100px",
      right: 0,
      width: "w-[172px]",
      hidden: false
    }
  },

  // Triple (Pricing)
  variant3: {
    left: {
      top: "360px",
      left: 0,
      width: "w-[123px]",
      hidden: false
    },
    middle: {
      top: "38px",
      left: "23%",
      width: "w-[112px]",
      hidden: false
    },
    right: {
      top: "-100px",
      right: 0,
      width: "w-[129px]",
      hidden: false
    }
  }
}

const BubblesBg = ({ 
  variant = "default",
  customPositions,
  className = "",
  isFixed = false  // New prop to control position type
}) => {
  const bubblesBgRef = useRef(null)

  useEffect(() => {
    const parent = bubblesBgRef.current.parentElement
    parent.style.position = "relative"
    parent.style.zIndex = 0
  }, [])

  const positions = customPositions || variants[variant] || variants.default

  return (
    <div
      className={`hidden md:block ${isFixed ? 'fixed md:top-[160px] xl:top-[80px] left-0' : 'absolute top-0 left-0'} w-full h-[1080px] ${className}`}
      ref={bubblesBgRef}
      style={{
        zIndex: -1,
      }}
    >
      {/* Left Bubble */}
      {!positions.left.hidden && (
        <div 
          style={{
            position: 'absolute',
            top: positions.left.top,
            left: positions.left.left,
          }}
        >
          <Image
            id="bubbly_background_left"
            className={`${positions.left.width} h-auto`}
          />
        </div>
      )}

      {/* Middle Bubble */}
      {!positions.middle.hidden && (
        <div 
          style={{
            position: 'absolute',
            top: positions.middle.top,
            left: positions.middle.left,
          }}
        >
          <Image
            id="bubbly_background_middle"
            className={`${positions.middle.width} h-auto`}
          />
        </div>
      )}

      {/* Right Bubble */}
      {!positions.right.hidden && (
        <div 
          style={{
            position: 'absolute',
            top: positions.right.top,
            right: positions.right.right,
          }}
        >
          <Image
            id="bubbly_background_right"
            className={`${positions.right.width} h-auto`}
          />
        </div>
      )}
    </div>
  )
}

export default BubblesBg